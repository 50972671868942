<template lang="pug">
	v-card(raised class="Window")
		v-toolbar(dark color="primary")
			v-toolbar-title(class="mx-auto")
				slot(name="header")
                

		v-card-text(class="body")
			slot

		v-card-actions(class="footer" v-if="isShowFooter()")
			slot(name="footer")
</template>

<script>
export default {
  name: 'Window',

  methods: {
		isShowHeader() {
			return !!this.$slots.header;
		},
		isShowFooter() {
			return !!this.$slots.footer;
		}
  }
}
</script>
