<template lang="pug">
window
		template(slot="header")
			span Registro

		v-form(@submit.prevent="request")

			v-text-field(
				v-model.trim="$v.form.name.$model"
				label="Nombre"
				autocomplete="name"
				prepend-icon="mdi-account"
				:error-messages="nameError"
			)

			v-select(
				v-model.trim="$v.form.industry.$model"
				label="Industria"
				item-text="label"
				item-value="id"
				prepend-icon="mdi-factory"
				:error-messages="industryError"
				:items="industries"
			)

			v-text-field(
				v-model.trim="$v.form.email.$model"
				label="Email"
				autocomplete="email"
				prepend-icon="mdi-email"
				:error-messages="emailError"
			)

			v-text-field(
				v-model="$v.form.password.$model"
				:error-messages="passwordError"
				autocomplete="new-password"
				label="Contraseña"
				type="password"
				prepend-icon="mdi-lock"
			)

			v-text-field(
				v-model="$v.form.confirmPassword.$model"
				:error-messages="confirmPasswordError"
				autocomplete="new-password"
				label="Confirma tu contraseña"
				type="password"
				prepend-icon="mdi-lock"
			)

			v-checkbox(
				color="primary"
				:error="$v.form.acceptTerms.$error"
				v-model="$v.form.acceptTerms.$model"
			)
				template(v-slot:label)
					div
						span Acepto las &nbsp;
						a(href="https://virtualitour.es/politica-de-privacidad" target="_blank") políticas de privacidad


			v-checkbox(
				color="primary"
				v-model="$v.form.acceptNotifications.$model"
        label="Acepto recibir informacion sobre descuentos y noticias"
			)

			v-card-actions(class="justify-center")
				v-btn(
					large
					block
					type="submit"
					color="primary"
					:loading="loading",
					:disabled="invalid || loading"
				) Registrarse

			div(class="pl-2")
				router-link(:to="{name: 'login'}") &lt; Volver

</template>

<script>

import {required, email, sameAs, minLength} from 'vuelidate/lib/validators';
import Window from '@/modules/access/components/Window.component';

export default {
	name: 'Register',
	data: () => {
		return {
			form: {
				email: '',
				password: '',
				confirmPassword: '',
				name: '',
				industry: '',
				acceptTerms: false,
        acceptNotifications: false
			}
		}
	},

	components: {
		Window
	},

	computed: {
		emailError() {
			const errors = [];
			const email = this.$v.form.email;

			if(email.$error) {
				const messages = this.errorMessages;
				if (!email.required) errors.push(messages.required)
				if (!email.email) errors.push(messages.email)
			}

			return errors
		},
		nameError() {
			const errors = [];
			const name = this.$v.form.name;

			if(name.$error) {
				const messages = this.errorMessages;
				if (!name.required) errors.push(messages.required)
			}

			return errors
		},
		industryError() {
			const errors = [];
			const name = this.$v.form.industry;

			if(name.$error) {
				const messages = this.errorMessages;
				if (!name.required) errors.push(messages.required)
			}

			return errors
		},
		passwordError() {
			const errors = [];
			const password = this.$v.form.password;

			if(password.$error) {
				const messages = this.errorMessages;
				if (!password.required) errors.push(messages.required)
				if (!password.minLength) errors.push(messages.password)
			}

			return errors
		},
		confirmPasswordError() {
			const errors = [];
			const confirmPassword = this.$v.form.confirmPassword;

			if(confirmPassword.$error) {
				const messages = this.errorMessages;
				if (!confirmPassword.sameAsPassword) errors.push(messages.passwordMatch)
			}

			return errors
		},

		invalid() {
			return this.$v.form.$error;
		}
	},
	props: {
		industries: {
			type: Array
		},
		loading: {
			type: Boolean,
			default: false
		},
		errorMessages: {
			type: Object,
			default() {
				return {
					email: 'Email es inválido',
					required: 'Campo es requerido',
					password: 'Contraseña debe contener al menos 6 carácteres',
					passwordMatch: 'Las contraseñas deben coincidir'
				};
			}
		}
	},

	methods: {
		request() {

			this.$v.form.$touch();

			if (this.$v.form.$invalid) return;

			this.$emit('request', {
				email: this.form.email,
				password: this.form.password,
				industry: this.form.industry,
				name: this.form.name,
        acceptNotifications: this.form.acceptNotifications
			});
		}
	},

	validations: {
		form: {
			email: {
				required,
				email
			},
      acceptNotifications: {},
			password: {
				required,
				minLength: minLength(6)
			},
			confirmPassword: {
				sameAsPassword: sameAs('password')
			},
			name: {
				required
			},
			industry: {
				required
			},
			acceptTerms: {
				checked: isChecked => !!isChecked
			}
		}
	}
}
</script>

<style scoped>
	.social-access {
		background-color: #0000000a;
	}

	.btn-facebook {
		background-color: #3b5998;
		color: white;
	}

	.btn-google-plus {
		background-color: #d34836;
		color: white;
	}

	.bg-virtualitour {
		background-color: #0050b3;
	}
</style>
