<template lang="pug">
	register(:loading="loading" @request="registerRequest" :industries="industries")
</template>

<script>
import Register from '../components/Register.component';
import firebase from '@/modules/common/firebase';
import {getUserCountry} from '@/modules/common/helpers';
import queries from '../services/queries'
const firestore = firebase.firestore();
const analytics = firebase.analytics();

export default {
	name: 'RegisterView',
	components: {
		Register
	},
	data() {
		return {
			loading: false,
			industries: null
		};
	},

	mounted() {
		queries.getIndustries().then(industries => {
			this.industries = industries;
		});
	},

	methods: {
		async registerRequest(userData) {
			this.loading = true;

      const country = await getUserCountry();

			const createUser = firebase.auth().createUserWithEmailAndPassword(userData.email, userData.password);

			createUser
				.then(this.saveUserExtraInfo.bind(this, {...userData, country}))
				.then(this.sendEmailVerification.bind(this))
				.then(this.onSuccess.bind(this))
				.catch(this.onFailedRequest.bind(this));
		},

		async saveUserExtraInfo(user, res) {
			const {name, industry, country, acceptNotifications} = user;

			analytics.setUserId(res.user.uid);

			await Promise.all([
				firestore.collection('users').doc(res.user.uid).set({
					industry,
          name,
          country,
          acceptNotifications
				}, {merge: true}),
				res.user.updateProfile({
					displayName: name
				})
			]);

			analytics.setUserProperties({industry});

			return {res, user};
		},

		async sendEmailVerification({res, user}) {
			await res.user.sendEmailVerification();
			return {res, user};
		},

		async onSuccess({res, user}) {
			const {industry} = user;

			analytics.logEvent('sign_up', {
				method: 'email/password',
				industry
			});

			this.loading = false;
			
			this.$notify({
				type: 'success',
				text: 'Te hemos enviado un correo para verificar tus credenciales'
			});

			setTimeout(() => {
				this.$router.push({name: 'home'});
			}, 2000);

			return {res, user};
		},

		onFailedRequest(error) {
			this.loading = false;

			this.$notify({
				text: this.getErrorMessage(error.code),
				type: 'error'
			});
		},

		getErrorMessage(code) {
			let message;

			switch(code) {
				case 'auth/email-already-in-use':
					message = "Email ya se encuentra en uso"
					break;
				case 'auth/invalid-email':
					message = "Email no es váliddo"
					break;
				case 'auth/operation-not-allowed':
					message = "No se admite el registro por email"
					break;
				case 'auth/weak-password':
					message = "Su contraseña no es lo suficientemente fuerte"
					break;

				case 'permision denied rules':
					break;

				default:
					message = 'Error desconocido.'
			}

			return message;
		}
	},
}
</script>
